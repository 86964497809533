.header-controls {
    display: flex;
    flex-direction: row;
}

.header-controls > .log-in-button {
    background: transparent;
    border: 2px solid #333333;
    margin-right: 16px;
}

@media (max-width: 590px) {
    .header-buttons-block .sign-up-button,
    .header-buttons-block .log-in-button,
    .header-buttons-block .log-out-button {
        height: 24px;
        border-radius: 4px;
        font-size: 16px;
        width: 70px;
        margin-right: 0;
    }

    .header-controls > .log-in-button {
        margin-right: 8px;
    }
}