.header-controls {
  flex-direction: row;
  display: flex;
}

.header-controls > .log-in-button {
  background: none;
  border: 2px solid #333;
  margin-right: 16px;
}

@media (max-width: 590px) {
  .header-buttons-block .sign-up-button, .header-buttons-block .log-in-button, .header-buttons-block .log-out-button {
    height: 24px;
    width: 70px;
    border-radius: 4px;
    margin-right: 0;
    font-size: 16px;
  }

  .header-controls > .log-in-button {
    margin-right: 8px;
  }
}

/*# sourceMappingURL=index.71b0291f.css.map */
